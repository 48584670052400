import {onCLS, onFID, onLCP, onTTFB, onINP, type Metric} from 'web-vitals';
import {PubSub} from '@cad/static-next-lib';

interface IClsEntry {
  value: number;
  sources: string[];
}

interface IParams {
  layoutclass: string;
  deviceclass: string;
  ff: string;
}

/**
 * Wrapper for using Google Web Vitals measuring API
 * https://github.com/GoogleChrome/web-vitals
 */
class WebVitals {
  private analyticsUrl_: string;
  private clsEntries: IClsEntry[];
  private metricNeeded_: string[];
  private metricsSent_: boolean;
  private alreadyGotDeviceClass_: boolean;
  private analyticsBody_: Record<string, string>;

  constructor() {
    this.analyticsUrl_ = '';
    this.clsEntries = [];
    this.metricNeeded_ = ['CLS', 'FID', 'LCP', 'TTFB', 'INP'];
    this.metricsSent_ = false; // send metrics only once
    this.alreadyGotDeviceClass_ = false;
    this.analyticsBody_ = {};
  }

  /** Check if Vitals analytics is enabled by retrieving POST URL */
  getAnalyticsUrl(): string {
    return (window.ui?.webvitals) ? window.ui.webvitals.endpoint : '';
  }

  setAnalyticsUrl(url: string) {
    this.analyticsUrl_ = url;
  }

  /** Set some initial values collected to analytics body */
  initBody(deviceClass: string, layoutClass: string) {
    this.analyticsBody_.deviceclass = deviceClass;
    this.analyticsBody_.layoutclass = layoutClass;
  }

  /** Clears body, for testing purpose */
  resetBody() {
    this.analyticsBody_ = {};
    this.clsEntries = [];
    this.metricsSent_ = false;
  }

  /** Handler for Google API */
  collectLayoutShift(metric: Metric) {
    try {
      if (window.ui.webvitals?.debugCls) {
        metric.entries.slice(0, 10).forEach(entry => {
          const layoutShift = entry as LayoutShift;
          if (layoutShift?.sources?.length > 0) {
            this.clsEntries.push(this.convertLayoutShiftEntry(layoutShift));
          }
        });
      }
    } finally {
      this.collectMetric(metric);
    }
  }

  /** Handler for Google API */
  collectTTFB(metric: Metric) {
    try {
      const first = metric.entries[0] as PerformanceNavigationTiming;
      if (first) {
        this.analyticsBody_.redirectStart = first.redirectStart.toFixed(3);
        this.analyticsBody_.redirectEnd = first.redirectEnd.toFixed(3);
        this.analyticsBody_.domainLookupStart = first.domainLookupStart.toFixed(3);
        this.analyticsBody_.domainLookupEnd = first.domainLookupEnd.toFixed(3);
        this.analyticsBody_.requestStart = first.requestStart.toFixed(3);
      }
    } catch (e) {
      console.warn('Unable to add TTFB details', e);
    } finally {
      this.collectMetric(metric);
    }
  }

  /** Converts layout shift entry into dto for backend. */
  convertLayoutShiftEntry(entry: LayoutShift): IClsEntry {
    const sources: string[] = [];
    entry.sources.slice(0, 10).forEach(source => {
      sources.push(this.convertElementToIdentifier(source.node));
    });
    return {
      'value': entry.value,
      'sources': sources
    };
  }

  /** Converts element to identifier / path. */
  convertElementToIdentifier(element: Node | undefined): string {
    try {
      if (element) {
        const elementPath = [];
        for (let elm = element; elm; elm = elm.parentNode as Node) {
          if (elm.nodeName === null) {
            continue;
          }
          let entry = elm.nodeName.toLowerCase();
          if (entry === 'html') {
            break;
          }

          const htmlElement = elm as HTMLElement;

          if (htmlElement.className) {
            entry += '.' + htmlElement.className.replace(/ /g, '.');
          }
          elementPath.push(entry);
        }
        elementPath.reverse();
        return elementPath.join(' ');
      }
      return 'no element';
    } catch (e) {
      console.error('Unable to create path', e);
      return 'error';
    }
  }

  /** Handler for Google API */
  collectMetric(metric: Metric) {
    console.log('[CWV]', metric);
    this.analyticsBody_[metric.name.toLowerCase()] = metric.value.toString();
    if (!this.metricsSent_) {
      if (this.allMetricGot()) {
        this.sendToAnalytics();
        this.metricsSent_ = true;
      }
    }
  }

  /** Check if all needed Vitals metrics are collected */
  allMetricGot() {
    let gotAll = true;
    const keys = Object.keys(this.analyticsBody_);
    this.metricNeeded_.forEach(m => {
      if (!keys.includes(m.toLowerCase())) {
        gotAll = false;
      }
    });
    return gotAll;
  }

  /** Builds a query string from a JSON object */
  objToQueryString(obj: Record<string, string>): string {
    let ret = '';
    const keys = Object.keys(obj);
    ret = keys.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');

    return ret;
  }

  /** Collects dynamic element heights. */
  collectElementHeights(): Map<string, number> {
    const result = new Map<string, number>();
    if (!window.ui?.webvitals) {
      return result;
    }

    const clsList = window.ui.webvitals.analyzeElementHeights;
    if (clsList) {
      clsList.forEach(clsName => {
        const elementCollection = document.getElementsByClassName(clsName);
        if (elementCollection && elementCollection.length > 0) {
          const element = elementCollection[0] as HTMLElement;
          if (element) {
            result.set(clsName, element.offsetHeight);
          }
        }
      });
    }
    return result;
  }

  /** Collects and sends measured data to back-end */
  sendToAnalytics() {
    const qs = this.objToQueryString(this.analyticsBody_);
    let url = this.analyticsUrl_;
    if (url.indexOf('?') > 0) {
      url += `&${qs}`;
    } else {
      url += `?${qs}`;
    }

    const body = {
      'elementHeights': this.collectElementHeights(),
      'clsEntries': this.clsEntries
    };

    const bodyAsString = JSON.stringify(body);
    console.log('[CWV] Details ', bodyAsString);

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    if (window.navigator.sendBeacon) {
      if (typeof Blob !== 'undefined') {
        window.navigator.sendBeacon(url, new Blob([bodyAsString], {
          type: 'application/json'
        }));
      } else {
        window.navigator.sendBeacon(url);
      }
    } else {
      void fetch(url, {
        method: 'POST',
        keepalive: true,
        headers: {
          'Content-Type': 'application/json'
        },
        body: bodyAsString
      });
    }
  }

  /** Start */
  run() {
    this.analyticsUrl_ = this.getAnalyticsUrl();
    if (this.analyticsUrl_) {
      PubSub.subscribe('ad:gotDeviceClass', rawParams => {
        const params = rawParams as IParams;
        if (Object.keys(params).length < 1) return; // in case params are not yet provided by AdService
        if (this.alreadyGotDeviceClass_) return; // don't exec twice

        this.initBody(params.deviceclass, params.layoutclass);
        try {
          onCLS(metric => this.collectLayoutShift(metric));
          onFID(metric => this.collectMetric(metric));
          onLCP(metric => this.collectMetric(metric));
          onTTFB(metric => this.collectTTFB(metric));
          onINP(metric => this.collectMetric(metric));
        } catch (e) {
          console.error('Error calling APIs:', e);
        }
        this.alreadyGotDeviceClass_ = true;
      });
    } else {
      console.log('[CWV] Collecting core web vitals disabled.');
    }
  }
}

export default new WebVitals();
