// Entry file for splitted JS build variant (for browsers support module importing)
// https://caniuse.com/#feat=es6-module
// Module JS must be imported and registered in markup

import Script from '../ts/services/script';
import Welcomeback from '../ts/services/welcomeback';
import UrlPath from '../ts/services/url-path';
import App from './base/app';

/*
 * @see https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 */
window.requestIdleCallback = window.requestIdleCallback
|| function(cb) {
  return setTimeout(function() {
    const start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: function() {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }, 1);
};

window.cancelIdleCallback = window.cancelIdleCallback
|| function(id) {
  clearTimeout(id);
};

window.yieldToMain = () => {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
};

// we obviously have JS
requestAnimationFrame(() => {
  document.documentElement.classList.remove('no-js');
});

requestIdleCallback(() => {
  Welcomeback.start();
}, {timeout: 5000});

// Load some JS before starting app.
const scriptsToPreload = [];

if (window.ui.tcfUrl) {
  scriptsToPreload.push(window.ui.tcfUrl);
}

if (window.ui.AdServiceConfig && window.ui.AdServiceConfig.enabled === true) {
  scriptsToPreload.push(UrlPath.sn + '/vendor/adservice2-defaultConfig.js');
}

// Bootstrap app when document is ready
const docReady = document.readyState;
const onDocReady = () => {
  Script.fetchAll(scriptsToPreload).catch(err => {
    console.error('Error loading scripts!', err);
  }).finally(() => {
    App.start();
  });
};
if (docReady === 'interactive' || docReady === 'complete') {
  onDocReady();
} else {
  document.addEventListener('DOMContentLoaded', onDocReady);
}
