import {PubSub} from '@cad/static-next-lib';

/** Will be replaced by container query in the future */
class ToolResizer {
  init() {
    try {
      this.setToolsClassSize();

      PubSub.subscribe('layoutChanged', () => {
        this.setToolsClassSize();
      });
      PubSub.subscribe('window:resize', () => {
        this.setToolsClassSize();
      });
      PubSub.subscribe('masonrized', () => {
        this.setToolsClassSize();
      });
    } catch (e) {
      PubSub.publish('sentry:log', {error: e});
    }
  }

  /** Check for Tool Size - sets specific class depending on size */
  setToolsClassSize() {
    try {
      const toolList = document.querySelectorAll<HTMLElement>('.mod-tool');
      toolList.forEach(element => {
        if (element.offsetWidth < 350) {
          element.classList.add('tiny');
          if (element.offsetWidth < 320) {
            element.classList.add('xs');
          } else {
            element.classList.remove('xs');
          }
          element.classList.remove('maxi');
          this.modifySuggestion(element, 'tiny');
        } else if (element.offsetWidth > 670) {
          element.classList.add('maxi');
          element.classList.remove('tiny');
          element.classList.remove('xs');
          this.modifySuggestion(element, 'maxi');
        } else {
          element.classList.remove('maxi');
          element.classList.remove('tiny');
          element.classList.remove('xs');
          this.modifySuggestion(element, 'maxi');
        }
      });
    } catch (e) {
      PubSub.publish('sentry:log', {error: e});
    }
  }

  modifySuggestion(currentNode: HTMLElement, condition: string) {
    const suggestionElements = currentNode.querySelectorAll('.suggestion');

    switch (condition) {
      case 'tiny':
        suggestionElements.forEach(element => {
          element.classList.remove('maxi');
          element.classList.add('tiny');
        });
        break;
      case 'maxi':
        suggestionElements.forEach(element => {
          element.classList.remove('tiny');
          element.classList.add('maxi');
        });
        break;
      default:
        break;
    }
  }
}

export default new ToolResizer();
