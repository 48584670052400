import {autoInit, Loader, Logger, PubSub, TCFHandler} from '@cad/static-next-lib';
import Analytics from '../services/analytics';
import ASLoader from '../../ts/services/asloader';
import {Config} from '../../ts/services/config';
import Magnifier from '../../ts/services/magnifier';
import Overlay from '../services/overlay';
import ToolResizer from '../../ts/services/toolresizer';
import UrlPath from '../../ts/services/url-path';
import Validation from '../../ts/services/validation';
import Viewport from '../../ts/services/viewport';
import WebVitals from '../../ts/services/webvitals';
import ServiceFactory from '../../ts/services/service-factory';
import {wcInit} from '../webcomponent-init';
/**
 * App
 */
class App {
  /**
  * constructor
  */
  constructor() {
    this.loader_ = Loader;
    this.logger_ = Logger;
    this.pubsub_ = PubSub;
    this.perf_ = window.performance || {mark: () => {}};
    this.sentryErrors_ = [];
    this.adDeviceClass_ = null;
  }

  /**
   * start
   */
  start() {
    try {
      const initializer = autoInit;
      if (typeof initializer !== 'function') {
        console.warn('No auto-initializer set!');
        return;
      }

      // collect early sentry logs (sentry will be loaded lazily)
      const sentryListener = this.pubsub_.subscribe('sentry:log', errorObj => {
        this.sentryErrors_.push(errorObj);
      });

      if (!Config.get('logging')) {
        this.logger_.mute();
      }

      if (!Config.get('adServiceEnabled')) {
        window.ui.adServiceEnabled
          = (window.ui.AdServiceConfig && window.ui.AdServiceConfig.enabled === true);
      }

      const assetPath = {
        css: UrlPath.css,
        js: UrlPath.jsAsset,
        modules: UrlPath.modules
      };

      TCFHandler.init();

      if (window.ui && window.ui.sentry) {
        // dynamically load sentry only if its feature toggled
        ServiceFactory.getService('ts/services/sentry.js').then(sentryInstance => {
          sentryListener.remove(); // let real sentry listen to the event
          sentryInstance.init(this.sentryErrors_);
        });
      }

      Overlay.init();
      Magnifier.init();
      ToolResizer.init();

      // Common events
      this.pubsub_.subscribe('mod:init', e => {
        this.logger_.log('app', `Module "${e.moduleName}" initialized.`);
      });

      // For initializing modules constructed with dynamic markups
      this.pubsub_.subscribe('mod:dynInit', modRoot => {
        initializer(modRoot, undefined, this.loader_, assetPath);
      });

      // For initializing web components constructed with dynamic markups
      this.pubsub_.subscribe('webcomponent:dynInit', modRoot => {
        wcInit(modRoot, UrlPath.modules);
      });

      window.addEventListener('resize', () => {
        this.pubsub_.publish('window:resize');
      });

      window.addEventListener('load', () => {
        this.pubsub_.publish('windowLoaded');
        console.log('windowLoaded');
      });

      // listen to visibilitystate change, i.e. browser tab becomes active/inactive
      document.addEventListener('visibilitychange', e => {
        this.pubsub_.publish('visibilityStateChanged', e);
        this.logger_.log('app', 'visibilityState (hidden) changed to: ' + e.target.hidden);
      });

      this.pubsub_.subscribe('asLoader:AdServiceLoaded', () => {
        if (window.ui && window.ui.adServiceEnabled) {
          // dynamically load ads only if its feature toggled
          ServiceFactory.getService('js/services/ads.js').then(adsInstance => {
            adsInstance.initialize();
            this.perf_.mark('adservice-init-end');
          });
        }
      });

      // Subscribe to event once as we would need to republish later within init Analytics callback.
      // Reason is because, the analytic and webvital services also subscribes to this event while the
      // ads service publishes this event, and we want to be able to run the adservice before the webvital and analytic service.
      this.pubsub_.subscribeOnce('ad:gotDeviceClass', params => {
        this.adDeviceClass_ = params;
      });

      window.addEventListener('message', e => {
        if (e.data.method === 'scrollup') {
          let target = 'iFrameResizer0';
          if (!e.data.offSet) {
            e.data.offSet = 100;
          }
          if (e.data.id) {
            target = e.data.id;
          }
          Viewport.scrollViewPortTo(/** @type HTMLElement */ (document.getElementById(target)), e.data.offSet);
        }
      }, false);

      // eventListener for external iFrames
      window.addEventListener('message', event => {
        // Glomex
        if (event.origin === 'https://player.glomex.com') {
          console.log('glomex events', event);
          const data = event.data.__tcfapiCall;
          const iframes = Array.from(document.querySelectorAll('iframe.video__content'))
            .filter(frame => frame.src.indexOf('glomex') > -1);
          if (data && (data.command === 'getTCData' || data.command === 'addEventListener')) {
            console.log('glomex data', data);
            iframes.forEach(iframe => {
              TCFHandler.getTCData((tcData, success) => {
                console.log('glomex', 'sending');
                iframe.contentWindow.postMessage({
                  __tcfapiReturn: {
                    returnValue: tcData,
                    success: success,
                    callId: data.callId
                  }
                }, '*');
              });
            });
          }
        }

        // External embed
        if (event.data.externalEmbed) {
          this.pubsub_.publish('externalembed:loaded', event.data.externalEmbed);
        }
      }, false);

      // Load AdService and start ads/tracking
      // CADNPCA-8331
      this.loadAdService();

      // Init Analytics
      try {
        // TCF may throw exception because of e.g. iframe permission
        Analytics.init(undefined, TCFHandler.getPermission('brainTracking'));
      } catch {
        Analytics.init(undefined);
      }
      WebVitals.run();

      // republish event now Analytic and WebVital has been init.
      this.pubsub_.publish('ad:gotDeviceClass', this.adDeviceClass_);

      // Auto initialize modules on page
      // Modules here are lazy loaded once visible in browsers viewport
      initializer(undefined, undefined, this.loader_, assetPath, true);

      wcInit(document, UrlPath.modules);

      /**
        * Works as throttle function in lodash or underscore. Scroll event is wrapped in a timeout as to avoid
        * making it trigger hundreds of times in a second.
        *
        * @param {number} timeout
        */
      const throttleWindowScroll = timeout => {
        let scrollTimer;

        window.addEventListener('scroll', () => {
          if (scrollTimer) {
            window.clearTimeout(scrollTimer);
          }
          scrollTimer = setTimeout(() => {
            this.pubsub_.publish('windowScrolling');
          }, timeout);
        });
      };

      throttleWindowScroll(50);

      // global function for ad-refreshing, originally only for iframe integration
      // CADNPCA-4362
      if (!window.potec) {
        window.potec = {};
      }

      if (typeof window.potec.handleAdRefresh === 'undefined') {
        window.potec.handleAdRefresh = function(whichAdsObj) {
          try {
            if (whichAdsObj) {
              const allParams = {
                trigger: '',
                method: 'adRefresh',
                top: (Validation.hasProperty(whichAdsObj, 'top') && whichAdsObj['top'] === true),
                sky: (Validation.hasProperty(whichAdsObj, 'sky') && whichAdsObj['sky'] === true),
                rect: (Validation.hasProperty(whichAdsObj, 'rect') && whichAdsObj['rect'] === true)
              };
              window.postMessage(allParams, '*');
            }
          } catch (e) {
            console.log(e);
            this.pubsub_.publish('sentry:log', {error: e});
          }
        };
      }
    } catch (e) {
      console.log(e);
      this.pubsub_.publish('sentry:log', {error: e});
    }
  }

  /**
   * loadAdService
   */
  loadAdService() {
    if (Config.get('adServiceEnabled')) {
      const biddingConfigUrls = window.ui.AdServiceConfig.biddingConfigUrls;
      const layoutClass = Viewport.getLayoutClassForCurrentViewPortWidth();

      ASLoader.adServiceConfig = window.ui.AdServiceConfig;
      ASLoader.adBiddingConfigFileUrl = biddingConfigUrls[layoutClass];
      ASLoader.run();
    } else {
      ASLoader.sendFallbackTrackingInfo();
    }
  }
}

export default new App();
