import {AjaxP, Logger, PubSub} from '@cad/static-next-lib';

/**
 * Recommendation Pixel
 * https://mam-confluence.1and1.com/x/Fw4wFQ
 */
class RecoTracking {
  private readonly startTime: number;

  private completionRate = 0; // should be 0-100, -1 in case unavailable e.g. video time event blocked
  private dataAlreadySent = false;
  private recoUrl: string;
  private pixelId: string;
  private contentType: string;
  private feedbackCount = 0; // 1-100
  private socialShareCount = 0; // 1-100
  private piCount = 0; // 2-1000

  constructor() {
    this.startTime = Date.now();
    this.pixelId = '';
  }

  init() {
    if (window.ui.recom) {
      this.recoUrl = window.ui.recom.endpoint;
      this.contentType = window.ui.recom.contentType;
      if (this.contentType === 'Video') {
        this.completionRate = -1; // -1 in case unavailable e.g. video time event blocked
      }
    } else {
      return; // no consent available, reco is deactivated
    }

    this.attachRecoListeners();

    // send tracking info at 'unloading'
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event#sending_end-of-session_analytics_on_transitioning_to_hidden
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.sendTrackingData();
      }
    });
  }

  attachRecoListeners() {
    PubSub.subscribe('content:recoCCR', data => {
      const d = data as {progress: number; contentType?: string};
      const prog = d.progress * 100 || 0;
      const cType = d.contentType ?? '';
      if ((this.contentType === cType) && (prog >= this.completionRate)) {
        this.completionRate = prog;
      }
    });

    PubSub.subscribe('globalReco:userFeedback', data => {
      this.increaseTrackingCounter(data as string);
    });

    /**
    * Own Recommendation Tracking
    * Uses recoUrl and appends brainzone if available
    * CADNPCA-5691
    */
    PubSub.subscribeOnce('analyticsReco:clicksReady', clicks => {
      if (this.recoUrl) {
        const hasQs = this.recoUrl.includes('?');
        const clickParams = (clicks && Array.isArray(clicks))
          ? (hasQs ? '&' : '?') + 'module=' + decodeURIComponent(clicks.join('.')) : '';

        void this.fireRecoTracking(this.recoUrl + clickParams);
      }
    });

    PubSub.subscribe('analyticsReco:clickOut', data => {
      const {recoUrl, clicks} = data as {recoUrl: string | null; clicks: string | null};
      if (recoUrl) {
        const hasQs = recoUrl.includes('?');
        const clickParams = clicks ? (hasQs ? '&' : '?') + 'module=' + decodeURIComponent(clicks) : '';

        void this.fireRecoTracking(recoUrl + clickParams);
      }
    });
  }

  increaseTrackingCounter(actionCountKey: string) {
    switch (actionCountKey) {
      case 'feedbackCount':
        this.feedbackCount++;
        break;
      case 'socialShareCount':
        this.socialShareCount++;
        break;
      case 'piCount':
        this.piCount++;
        break;
    }
  }

  async fireRecoTracking(url: string) {
    await AjaxP.putJSON(url, {}).then(ret => {
      const pixelId = (ret as {pixelId: string}).pixelId;
      if (pixelId) {
        this.pixelId = pixelId;
      }
    });
  }

  limitNumberWithinRange(input: number, min = 1, max = 100) {
    const roundedInput = Math.round(input);
    return Math.min(Math.max(roundedInput, min), max);
  }

  getTrackingData(): Record<string, string> {
    const endTime = Date.now();
    const timeOnPage = Math.floor((endTime - this.startTime) / 1000);
    const trackingData: Record<string, string> = {
      'pixelId': this.pixelId,
      'timeOnPage': timeOnPage.toString()
    };

    if (this.contentType === 'Article' || this.contentType === 'Video' || this.contentType === 'Slideshow') {
      if (this.completionRate > -1) {
        this.completionRate = this.limitNumberWithinRange(this.completionRate, 0, 100);
        trackingData.completionRate = this.completionRate.toString();
      }
    }
    if (this.feedbackCount > 0) {
      trackingData.feedbackCount = this.limitNumberWithinRange(this.feedbackCount).toString();
    }
    if (this.socialShareCount > 0) {
      trackingData.socialShareCount = this.limitNumberWithinRange(this.socialShareCount).toString();
    }
    if (this.piCount > 1) {
      trackingData.piCount = this.limitNumberWithinRange(this.piCount, 2, 1000).toString();
    }
    return trackingData;
  }

  sendTrackingData() {
    if (this.dataAlreadySent || this.pixelId === '') {
      return;
    }

    if (this.recoUrl) {
      try {
        const recomUrlObj = new URL(this.recoUrl);
        for (const [k, v] of Object.entries(this.getTrackingData())) {
          recomUrlObj.searchParams.set(k, v);
        }
        this.fireTrackingRequest(recomUrlObj.href);
        window.ui.recom.urlsend = recomUrlObj.href; // For selenium test
        this.dataAlreadySent = true;
      } catch (e) {
        Logger.logUnknownError(e, 'RecoTracking');
      }
    }
  }

  fireTrackingRequest(url: string) {
    navigator.sendBeacon(url);
  }
}

export default new RecoTracking();
