import {Logger, PubSub} from '@cad/static-next-lib';
import {Config} from './config';
import Script from './script';

import type {IAdServiceConfig} from '../../definitions';

class ASConfig implements IAdServiceConfig {
  adServiceBundleUrl = '';
  adServiceConnectorUrl = '';
  biddingConfigUrls = {
    b: '',
    m: '',
    s: ''
  };

  biddingEnabled = true;
  config = {
    configurations: {
      prebidVideoTimeout: {
        s: 0,
        m: 0,
        b: 0
      },
      visibilityLookahead: 0
    },
    exclusionRules: {},
    prebidRules: {}
  };

  exclusionRules = {};
  prebidRules = {};
  enabled = true;
  init = {};
  nonFriendlyIFrameDocument = '';
  pageCategory = '';
  configurations = {};
}

class AdComponentsLoaded {
  [key: string]: boolean;
  bundle: false;
  config: false;
}

/** Load AdService and related files */
class ASLoader {
  private adBiddingConfigFileUrl_ = '';
  private adServiceConfig_: IAdServiceConfig = new ASConfig();
  private externalConfig_: IAdServiceConfig;
  private adComponentsLoaded_ = new AdComponentsLoaded();
  private pubSub_ = PubSub;
  private logger_ = Logger;
  private perf_: Performance;

  /** Sets ad-bidding config file URL. */
  set adBiddingConfigFileUrl(val: string) {
    this.adBiddingConfigFileUrl_ = val;
  }

  /** Sets AdService configuration. */
  set adServiceConfig(val: IAdServiceConfig) {
    this.adServiceConfig_ = val;
  }

  get externalConfig() {
    return this.externalConfig_;
  }

  /** Checks and patches requireJS methods, because prebid config file uses requireJS.define() to set data. */
  checkRequireJs() {
    if ((typeof window.requireJS === 'object') && (typeof window.requireJS.define === 'function')) {
      // ignore
    } else {
      this.mockRequireJs();
    }
  }

  /** Patches `define()` method. */
  mockRequireJs() {
    window.requireJS = {
      define: obj => {
        this.externalConfig_ = obj as IAdServiceConfig;
      },
      require: () => {return;}
    };
  }

  /** Starts the service. */
  run() {
    if (!Config.get('adServiceEnabled')) {
      this.sendFallbackTrackingInfo();
      return;
    }

    this.checkRequireJs();

    // avoid requireJS CORS loading
    window.ui.noCORSUrls = window.ui.noCORSUrls || [];
    window.ui.noCORSUrls.push(this.adBiddingConfigFileUrl_);

    this.perf_ = window.performance
    ?? {mark: () => {return;}};

    this.loadAdServiceBundle(() => {
      this.setAdComponentLoaded('bundle'); // in case adservice is blocked, still set variable so fallback can be triggered
    });
    this.loadAdServiceConfig((data: IAdServiceConfig) => {
      this.applyExternalConfig(data);
    }, () => {
      this.useDefaultConfig();
    });
  }

  /** If ad-bidding enabled */
  adBiddingEnabled(): boolean {
    return this.adServiceConfig_.biddingEnabled || false;
  }

  /** If AdService is not available somehow, still trigger tracking module with fallback data */
  sendFallbackTrackingInfo() {
    let layoutClass = 's';
    const currentWidth = document.documentElement.clientWidth;

    document.documentElement.classList.add('ab-active');

    if (currentWidth >= 768 && currentWidth < 1024) {
      layoutClass = 'm';
    } else if (currentWidth >= 1024) {
      layoutClass = 'b';
    }

    this.pubSub_.publish('ad:gotDeviceClass', {
      layoutclass: layoutClass,
      deviceclass: 'undef',
      ff: 'unknown'
    });
  }

  /** Send message to trigger Ad module */
  startAdService() {
    if (typeof window.AdService === 'undefined') {
      this.sendFallbackTrackingInfo();
    } else {
      if (!this.adServiceConfig_.config) {
        // use default config
        this.adServiceConfig_.config = {
          exclusionRules: this.adServiceConfig_.exclusionRules,
          prebidRules: this.adServiceConfig_.prebidRules,
          configurations: {}
        };
      }
      this.perf_.mark('adservice-init-start');
      this.pubSub_.publish('asLoader:AdServiceLoaded');
    }
  }

  /** Set maker: ad component as loaded */
  setAdComponentLoaded(componentType: string) {
    this.adComponentsLoaded_[componentType] = true;
    if (this.adComponentsLoaded_.bundle && this.adComponentsLoaded_.config) {
      window.ui.AdServiceConfig = Object.assign(window.ui.AdServiceConfig, this.adServiceConfig_);
      this.startAdService();
    }
  }

  /** As is */
  useDefaultConfig() {
    this.setAdComponentLoaded('config');
  }

  /** Try to load pre-bid config file provided by TAM. */
  loadAdServiceConfig(cb: (config: IAdServiceConfig) => void, errCb: () => void) {
    if (this.adBiddingEnabled()) {
      if (this.adBiddingConfigFileUrl_) {
        Script.fetch(this.adBiddingConfigFileUrl_, false, false)
          .then(() => {
            cb(this.externalConfig_);
          })
          .catch(() => {
            errCb();
          });
      }
    } else {
      // ad-bidding disabled
      this.useDefaultConfig();
    }
  }

  /** Try load the bundled AdService file. */
  loadAdServiceBundle(errCb: () => void) {
    const adServiceUrl = window.ui.AdServiceAlternativeUrl || window.ui?.AdServiceConfig?.adServiceBundleUrl;

    if (adServiceUrl) {
      this.perf_.mark('adservice-load-start');
      Script.fetch(adServiceUrl, undefined, false)
        .then(() => {
          this.perf_.mark('adservice-load-end');
          this.setAdComponentLoaded('bundle');
        })
        .catch(() => {
          if (typeof errCb === 'function') {
            errCb();
          }
        });
    } else {
      this.logger_.log('Adservice', 'Cant load adservice bundle provide adservice url');
    }
  }

  /** When got pre-bid config data, update ad service config */
  applyExternalConfig(data: IAdServiceConfig) {
    this.adServiceConfig_.config = {
      exclusionRules: data.exclusionRules,
      prebidRules: data.prebidRules,
      configurations: data.configurations || {}
    };
    this.setAdComponentLoaded('config');
  }
}

export default new ASLoader();
