import {Logger} from '@cad/static-next-lib';
import UrlPath from './url-path';

interface DefaultImport<T> {
  default: T;
}

/** Creates and returns "on demand" lazy load services */
class ServiceFactory {
  private loadedServices: Map<string, unknown>;

  constructor() {
    this.loadedServices = new Map<string, unknown>();

    Logger.log('ServiceFactory', 'initialization completed');
  }

  /**
  * Returns the requested service
  * @param {string} serviceFile js file for dynamic loading e.g. (sentry.js or ads.js)
  * @return {Promise<unknown>} service
  */
  async getService(serviceFile: string): Promise<unknown> {
    const serviceInstance = this.loadedServices.get(serviceFile);

    if (!serviceInstance) {
      const service = await import(`${UrlPath.sn}/${serviceFile}`) as DefaultImport<unknown>;

      if (service) {
        this.loadedServices.set(serviceFile, service.default);
        return service.default;
      } else {
        throw new Error('Unable to load the service: ' + serviceFile);
      }
    } else {
      return serviceInstance;
    }
  }
}

export default new ServiceFactory();
